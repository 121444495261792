import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import moment from "moment";
import { VisitInfo } from "@/legacy/features/visits/models/visits.models";
import { DgGridContainer, DgWindowSizeMixin } from "@dasgate/uikit";
import { DataField } from "@/ui/_components/DataField";
import { VisitCard } from "@/ui/Visits/_components/VisitCard";

@Component({ components: { DataField, VisitCard, DgGridContainer } })
export default class VisitItem extends DgWindowSizeMixin {
  @Prop({ required: true }) public visit!: VisitInfo;
  @Prop({ required: false, default: false }) public isReceptionist!: boolean;

  get dateInfo() {
    return {
      title: moment(this.visit.expected_at, "YYYY-MM-DD").format("DD/MM/YYYY"),
      subtitle:
        this.formatDateUsingTimezone(this.visit.expected_at, this.visit.location.timezone).format("HH:mm") +
        " - " +
        this.formatDateUsingTimezone(this.visit.leaving_at, this.visit.location.timezone).format("HH:mm"),
    };
  }

  public formatDateUsingTimezone(datetime: string, timezone: string) {
    return moment(datetime).tz(timezone);
  }

  public onClick() {
    this.$emit("on-click");
  }
}
