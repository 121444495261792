import { Component, Vue } from "vue-property-decorator";
import { DgHelper, DgColumn, DgText, DgRow, DgFlex } from "@dasgate/uikit";

import { VisitDetailField } from "@/ui/Visits/_components/VisitDetailField";
import { VisitCard } from "@/ui/Visits/_components/VisitCard";
import { Loading } from "@/ui/_components/Loading";

import { isEmpty, isUndefined } from "@/core/shared/utils";
import { VisitInfo } from "@/legacy/features/visits/models/visits.models";

import moment from "moment-timezone";

@Component({
  components: {
    DgHelper,
    DgColumn,
    DgText,
    VisitCard,
    DgRow,
    DgFlex,
    VisitDetailField,
    Loading,
  },
})
export default class VisitConfirmation extends Vue {
  get message(): string {
    if (this.errorMessage) {
      return this.$t(`common.visits.confirmation.${this.errorMessage}`) as string;
    }

    if (["accepted", "cancelled"].includes(this.visit.status)) {
      return this.$t(`common.visits.confirmation.${this.visit.status}`) as string;
    }

    return this.$t("common.visits.confirmation.other") as string;
  }

  get messageType(): string {
    const messageTypesMap = {
      accepted: "success",
      cancelled: "error",
      error: "error",
    };
    const messageType = messageTypesMap[isEmpty(this.errorMessage) ? this.visit.status : "error"];
    return isUndefined(messageType) ? "warning" : messageType;
  }

  get hasVisit(): boolean {
    return !isEmpty(this.visit);
  }

  public isLoading = true;
  public errorMessage = "";

  public visit: VisitInfo = {} as VisitInfo;

  public async mounted() {
    this.$container.authRepository.unset();
    await this.sendRequest();
    this.isLoading = false;
  }

  public formatDateUsingTimezone(datetime: string, timezone: string, format: string) {
    return moment(datetime).tz(timezone).format(format);
  }

  public async sendRequest() {
    await this.$services.visitsService
      .confirmation({
        token: this.$route.params.token,
        visitId: this.$route.params.visitId,
      })
      .then(result => {
        if (typeof result === "string") {
          this.errorMessage = result;
        } else {
          this.visit = result;
        }
      });
  }

  get visitDate(): string {
    return this.formatDateUsingTimezone(
      this.visit.expected_at,
      this.visit.location.timezone,
      this.$t("tenant.dateFormat") as string
    );
  }

  get expectedHour(): string {
    return this.formatDateUsingTimezone(
      this.visit.expected_at,
      this.visit.location.timezone,
      this.$t("tenant.hourFormat") as string
    );
  }

  get leavingHour(): string {
    return this.formatDateUsingTimezone(
      this.visit.leaving_at,
      this.visit.location.timezone,
      this.$t("tenant.hourFormat") as string
    );
  }
}
