import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { VisitStatus } from "@/legacy/features/visits/models/visits.models";
import { DgColumn, DgFlex, DgRow, DgText } from "@dasgate/uikit";
import { Status } from "@/legacy/features/visits/config/config";

@Component({ components: { DgText, DgColumn, DgRow, DgFlex } })
export default class VisitCard extends Vue {
  public statusMap = Status;

  @Prop({ required: true }) public status!: VisitStatus;

  get clickable() {
    return this.$listeners && this.$listeners["on-click"];
  }

  get classes() {
    return [getStatusClass(this.status), this.clickable && " clickable"];
  }

  public onClick() {
    this.$emit("on-click");
  }
}

const getStatusClass = (status: VisitStatus) => {
  if (status === "cancelled") {
    return "canceled";
  }
  if (status === "requested") {
    return "pending";
  }
  if (status === "accepted") {
    return "accepted";
  }
  if (status === "checked_out") {
    return "checked_out";
  }

  return "confirmed";
};
