interface IStatus {
  [key: string]: object;
}
export const Status = Object.freeze({
  requested: {
    literal: "requested",
    variant: "warning",
  },
  accepted: {
    literal: "accepted",
    variant: "primary",
  },
  checked_in: {
    literal: "checked_in",
    variant: "success",
  },
  checked_out: {
    literal: "checked_out",
    variant: "warning",
  },
  cancelled: {
    literal: "cancelled",
    variant: "danger",
  },
}) as IStatus;
