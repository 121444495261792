import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment-timezone";
import { DgBox, DgColumn, DgFlex, DgRow, DgText } from "@dasgate/uikit";
import { HistoryEntity, VisitInfo } from "@/legacy/features/visits/models/visits.models";
import { Status } from "@/legacy/features/visits/config/config";
import { RouteNames } from "@/ui/_router";
import { Prop } from "vue-property-decorator";

@Component({ components: { DgText, DgColumn, DgBox, DgRow, DgFlex } })
export default class VisitHistory extends Vue {
  @Prop() public data!: VisitInfo;

  public status = Status;

  public created() {
    if (this.data === undefined) {
      this.$router.push({ name: RouteNames.VisitList });
    }
  }

  public formatDateUsingTimezone(datetime: string, timezone: string) {
    return moment(datetime).tz(timezone);
  }

  public historyTitle(history: HistoryEntity) {
    return this.$t("common.visits.history." + history.action + ".title");
  }

  public historyMessage(history: HistoryEntity) {
    if (history.message && this.$te("common.visits.history." + history.action + "." + history.message)) {
      return this.$t("common.visits.history." + history.action + "." + history.message);
    }
    if (history.message) {
      return history.message;
    }
  }

  public historyDate(history: HistoryEntity) {
    return this.formatDateUsingTimezone(history.datetime, this.data.location.timezone).format(
      this.$t("tenant.dateHourFormat") as string
    );
  }
}
