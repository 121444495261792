import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import moment from "moment";
import Printd from "printd";
import { VisitDetailField } from "../../_components/VisitDetailField";
import { VisitCard } from "@/ui/Visits/_components/VisitCard";
import { RouteNames } from "@/ui/_router";
import { Status } from "@/legacy/features/visits/config/config";
import { GetUserCredentialsResponse } from "@/legacy/features/portal/models/portal.models";
import { VisitInfo } from "@/legacy/features/visits/models/visits.models";
import {
  DgAvatar,
  DgBox,
  DgButton,
  DgColumn,
  DgFlex,
  DgGridContent,
  DgModal,
  DgRow,
  DgSeparator,
  DgText,
  DgWindowSizeMixin,
} from "@dasgate/uikit";

@Component({
  components: {
    VisitCard,
    DgModal,
    VisitDetailField,
    DgGridContent,
    DgSeparator,
    DgButton,
    DgColumn,
    DgRow,
    DgText,
    DgBox,
    DgAvatar,
    DgFlex,
  },
})
export default class VisitInfoView extends DgWindowSizeMixin {
  @Prop() public data!: VisitInfo;

  public status = Status;
  public showModal = false;
  public showErrorModal = false;
  public errorMessage = "";
  public qrdata!: string;
  protected qrLoading = false;
  private print: Printd = new Printd();

  public mounted() {
    if (this.data === undefined) {
      this.$router.push({ name: RouteNames.MyVisits });
      return;
    }
  }

  public created() {
    this.getQrImage();
  }

  get shouldShowCancel() {
    return this.data.status === "requested" || this.data.status === "accepted";
  }

  get canUseQr(): boolean {
    const notAllowedStatus: string[] = ["requested", "rejected", "cancelled"];

    if (notAllowedStatus.includes(this.data.status)) {
      return false;
    }
    return true;
  }

  get isVisitor(): boolean {
    return this.$appStore.getters.isVisitor;
  }

  get isReceptionist(): boolean {
    return this.$appStore.getters.isReceptionist;
  }

  get modalOpened(): boolean {
    return !!this.showModal;
  }

  get errorModalOpened(): boolean {
    return !!this.showErrorModal;
  }

  public formatDateUsingTimezone(datetime: string, timezone: string) {
    return moment(datetime).tz(timezone);
  }

  public cancelRequest() {
    this.setShowModal(true);
  }

  public closeModal() {
    this.setShowModal(false);
  }

  public closeErrorModal() {
    this.setShowErrorModal(false);
  }

  public setShowErrorModal(show: boolean) {
    this.showErrorModal = show;
  }

  public setShowModal(show: boolean) {
    this.showModal = show;
  }

  public async cancelVisit() {
    const result = await this.$services.visitsService.deleteVisit({ id: this.data.id });
    this.setShowModal(false);
    if (result.success) {
      if (this.isVisitor) {
        this.$router.push({ name: RouteNames.MyVisits });
      } else {
        this.$router.push({ name: RouteNames.VisitList });
      }
    } else {
      this.setShowErrorModal(true);
      this.errorMessage = result.errorMessage as string;
    }
  }

  public printQr(): void {
    const img = document.createElement("img");
    img.src = this.qrdata;
    this.print.print(img);
  }

  private async getQrImage() {
    this.qrLoading = true;
    const credentials: GetUserCredentialsResponse = await this.$services.userService.getCredentials({
      id: this.data.visitor as string,
    });
    const qrCredential = credentials.credentials.find(credential => credential.credentialType === "biometric_qr");
    if (qrCredential) {
      const response = await this.$services.userService.getQrImage({
        userId: this.data.visitor as string,
        sid: qrCredential.sid,
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.qrdata = URL.createObjectURL(response.content);
    }
    this.qrLoading = false;
  }
}
