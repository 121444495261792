import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DgBadge, DgColumn, DgRow, DgTab, DgTabs, DgText } from "@dasgate/uikit";
import { VisitInfo as VisitInfoModel } from "@/legacy/features/visits/models/visits.models";
import { VisitInfo } from "./VisitInfo";
import { VisitHistory } from "./VisitHistory";
import moment from "moment-timezone";

@Component({ components: { VisitHistory, VisitInfo, DgTabs, DgTab, DgColumn, DgText, DgBadge, DgRow } })
export default class VisitDetail extends Vue {
  @Prop() public data!: VisitInfoModel;

  public formatDateUsingTimezone(datetime: string, timezone: string) {
    return moment(datetime).tz(timezone);
  }

  public goBack() {
    this.$router.go(-1);
  }
}
