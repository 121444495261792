import moment from "moment-timezone";
import Component from "vue-class-component";
import { VisitItem } from "./_components/VisitItem";
import {
  DgBox,
  DgButton,
  DgColumn,
  DgGridContainer,
  DgGridContent,
  DgPagination,
  DgRow,
  DgSearch,
  DgSelect,
  DgText,
  DgWindowSizeMixin,
} from "@dasgate/uikit";
import { VisitListHeader } from "./_components/VisitListHeader";
import { RouteNames } from "@/ui/_router/names";
import { GetVisitsRequest, VisitInfo } from "@/legacy/features/visits/models/visits.models";
import { Status } from "@/legacy/features/visits/config/config";
import { PagedResult } from "@/legacy/models/shared";
import { GetUserDetailRequest } from "@/legacy/features/portal/models/portal.models";
import { Dictionary } from "vue-router/types/router";
import { List } from "@/ui/_components/List";

@Component({
  components: {
    VisitItem,
    VisitListHeader,
    DgGridContent,
    DgGridContainer,
    DgColumn,
    DgButton,
    DgSearch,
    DgSelect,
    DgText,
    DgRow,
    DgBox,
    DgPagination,
    List,
  },
})
export default class VisitsList extends DgWindowSizeMixin {
  private visitorPageSize = 4;
  private receptionistPageSize = 10;
  public status = Status;
  public isLoading = true;
  public dateInput: Dictionary<string> = {
    fromDate: moment().local().startOf("day").toISOString(),
    toDate: moment().local().endOf("day").toISOString(),
  };
  public userId = this.$appStore.getters.userId;
  public visitorBookmarks = [""];
  public visitorBookmarksPast = [""];
  public receptionistBookmarks = [""];
  public searchValidationError = "";

  public visitorFilterdata = {
    filter: "",
    visitor: this.userId,
    fromDate: moment().local().startOf("day").toISOString(),
    toDate: "",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pageSize: this.visitorPageSize,
    pageIndex: 1,
    bookmark: "",
    orderby: "expected_at",
    sorted: "ASC",
  } as GetVisitsRequest;

  public visitorFilterdataPast = {
    filter: "",
    visitor: this.userId,
    fromDate: "",
    toDate: moment().local().endOf("day").subtract(1, "days").toISOString(),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pageSize: this.visitorPageSize,
    pageIndex: 1,
    bookmark: "",
    orderby: "expected_at",
    sorted: "DESC",
  } as GetVisitsRequest;

  public receptionistFilterdata = {
    user: "",
    fromDate: this.dateInput.fromDate,
    toDate: this.dateInput.toDate,
    orderby: "expected_at",
    bookmark: "",
    sorted: this.dateInput.fromDate >= moment().local().startOf("day").toISOString() ? "ASC" : "DESC",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pageSize: this.receptionistPageSize,
    pageIndex: 1,
  } as GetVisitsRequest;

  public visits: PagedResult<VisitInfo> = {
    bookmark: "",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pageSize: this.visitorPageSize,
    totalPages: -1,
    totalItems: -1,
    items: [],
  };
  public pastVisits = this.visits;

  public async mounted() {
    this.$watch("dateInput", () => {
      this.receptionistFilterdata.pageIndex = 1;
      this.search();
    });
    this.restoreSearch();
    await this.search();
  }

  get options() {
    return [
      {
        text: this.$t("common.visits.fields.dates.today"),
        value: {
          fromDate: moment().local().startOf("day").toISOString(),
          toDate: moment().local().endOf("day").toISOString(),
        },
      },
      {
        text: this.$t("common.visits.fields.dates.tomorrow"),
        value: {
          fromDate: moment().local().startOf("day").add(1, "days").toISOString(),
          toDate: moment().local().endOf("day").add(1, "days").toISOString(),
        },
      },
      {
        text: this.$t("common.visits.fields.dates.week"),
        value: {
          fromDate: moment().local().startOf("day").toISOString(),
          toDate: moment().local().endOf("isoWeek").endOf("day").toISOString(),
        },
      },
      {
        text: this.$t("common.visits.fields.dates.all"),
        value: {
          fromDate: moment().local().startOf("day").toISOString(),
          toDate: "",
        },
      },
      {
        text: this.$t("common.visits.fields.dates.last-30-days"),
        value: {
          fromDate: moment().local().startOf("day").subtract(30, "days").toISOString(),
          toDate: moment().local().endOf("day").toISOString(),
        },
      },
    ];
  }

  get isReceptionist(): boolean {
    return this.$appStore.getters.isReceptionist;
  }

  get currentPage() {
    return this.isReceptionist ? this.receptionistFilterdata.pageIndex : this.visitorFilterdata.pageIndex;
  }

  get totalPages() {
    return this.visits.totalPages;
  }

  get dateOptions() {
    return this.options.map(({ value, text }) => ({ label: text, value }));
  }

  public async onNewVisit() {
    this.$router.push({ name: RouteNames.NewVisit });
  }

  public async onVisitDetail(visit: VisitInfo) {
    this.$router.push({
      name: RouteNames.VisitDetail,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      params: { id: visit.id, data: visit },
    });
  }

  public onFirst() {
    this.firstPage(this.isReceptionist ? this.receptionistFilterdata : this.visitorFilterdata);
  }

  public onPrevious() {
    this.backPage(this.isReceptionist ? this.receptionistFilterdata : this.visitorFilterdata);
  }

  public onNext() {
    this.nextPage(this.isReceptionist ? this.receptionistFilterdata : this.visitorFilterdata, this.visits);
  }

  public async search() {
    this.isLoading = true;
    if (this.isReceptionist) {
      this.searchValidationError = this.validateSearch(this.receptionistFilterdata.user as string);
      if (this.searchValidationError === "") {
        this.updateReceptionistFilterdata();
        this.saveSearch();
        await this.loadReceptionist();
      } else {
        this.visits.items = [];
        this.visits.totalItems = 0;
      }
    } else {
      await this.loadVisitant();
      this.pastVisits.items.forEach(item => {
        const visitor = { id: item.visitor } as GetUserDetailRequest;
        this.$services.userService.getUserDetail(visitor).then(userDetail => {
          item.visitorData = userDetail;
          this.$services.userService.getUserSelfie(visitor).then(userProfile => {
            item.visitorProfile = userProfile;
          });
        });
      });
    }
    this.visits.items.forEach(item => {
      const visitor = { id: item.visitor } as GetUserDetailRequest;
      this.$services.userService.getUserDetail(visitor).then(userDetail => {
        item.visitorData = userDetail;
        this.$services.userService.getUserSelfie(visitor).then(userProfile => {
          item.visitorProfile = userProfile;
        });
      });
    });
    this.isLoading = false;
  }

  private async loadReceptionist() {
    this.receptionistFilterdata.bookmark = this.receptionistBookmarks[this.receptionistFilterdata.pageIndex - 1];
    this.visits = await this.$services.visitsService.getVisits(this.receptionistFilterdata);
    this.receptionistBookmarks[this.receptionistFilterdata.pageIndex] = this.visits.bookmark;
    this.isLoading = false;
  }

  private async loadVisitant() {
    this.visitorFilterdata.bookmark = this.visitorBookmarks[this.visitorFilterdata.pageIndex - 1];
    this.visits = await this.$services.visitsService.getVisits(this.visitorFilterdata);
    this.visitorBookmarks[this.visitorFilterdata.pageIndex] = this.visits.bookmark;
    this.isLoading = false;
    this.visitorFilterdataPast.bookmark = this.visitorBookmarksPast[this.visitorFilterdataPast.pageIndex - 1];
    this.pastVisits = await this.$services.visitsService.getVisits(this.visitorFilterdataPast);
    this.visitorBookmarksPast[this.visitorFilterdataPast.pageIndex] = this.pastVisits.bookmark;
  }

  private updateReceptionistFilterdata() {
    this.receptionistFilterdata.fromDate = this.dateInput.fromDate;
    this.receptionistFilterdata.toDate = this.dateInput.toDate;
    this.receptionistFilterdata.sorted =
      this.dateInput.fromDate >= moment().local().startOf("day").toISOString() ? "ASC" : "DESC";
  }

  private validateSearch(input: string) {
    if (!input) {
      return "";
    }
    const isValidSearch = this.isEmail(input) || this.isAlphanumeric(input);
    if (!isValidSearch) {
      return "common.errors.not_dni_or_mail";
    } else {
      return "";
    }
  }

  private isEmail(input: string) {
    // tslint:disable-next-line:max-line-length
    const regexp = new RegExp(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return regexp.test(input);
  }

  private isAlphanumeric(input: string) {
    const regex = new RegExp("^[A-Z0-9]{8,}$");
    return regex.test(input);
  }

  private hasMoreItems(filterdata: GetVisitsRequest, data: PagedResult<VisitInfo>): boolean {
    return filterdata.pageIndex < data.totalPages;
  }

  private hasLessItems(filterdata: GetVisitsRequest): boolean {
    return filterdata.pageIndex > 1;
  }

  private isFirstPage(filterdata: GetVisitsRequest): boolean {
    return filterdata.pageIndex === 1;
  }

  private nextPage(filterdata: GetVisitsRequest, data: PagedResult<VisitInfo>): void {
    if (this.hasMoreItems(filterdata, data)) {
      filterdata.pageIndex = filterdata.pageIndex + 1;
      this.search();
    }
  }

  private backPage(filterdata: GetVisitsRequest): void {
    if (this.hasLessItems(filterdata)) {
      filterdata.pageIndex = filterdata.pageIndex - 1;
      this.search();
    }
  }

  private firstPage(filterdata: GetVisitsRequest): void {
    if (!this.isFirstPage(filterdata)) {
      filterdata.pageIndex = 1;
      this.search();
    }
  }

  private saveSearch(): void {
    this.$appStore.actions.saveVisitSearch(this.receptionistFilterdata, this.receptionistBookmarks);
  }

  private restoreSearch(): void {
    if (this.$appStore.getters.visitsFilterdata) {
      this.receptionistFilterdata = this.$appStore.getters.visitsFilterdata;
      this.receptionistBookmarks = this.$appStore.getters.visitsBookmarks;
      this.dateInput.fromDate = this.receptionistFilterdata.fromDate;
      this.dateInput.toDate = this.receptionistFilterdata.toDate;
    }
  }
}
