import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { DgColumn, DgLoading, DgPagination, DgText } from "@dasgate/uikit";

@Component({ components: { DgColumn, DgPagination, DgLoading, DgText } })
export default class List<Item> extends Vue {
  @Prop({ required: true }) public items!: Item[];
  @Prop({ required: true }) public totalPages!: number;
  @Prop({ required: true }) public currentPage!: number;
  @Prop({ required: true }) public isLoading!: boolean;
  @Prop({ required: false }) public error?: string;
  @Prop({ required: false }) public emptyText?: string;

  @Emit() onFirstPage() {
    return;
  }

  @Emit() onPreviousPage() {
    return;
  }

  @Emit() onNextPage() {
    return;
  }

  @Emit() onLastPage() {
    return;
  }

  get showOnLastPage() {
    return this.$listeners && this.$listeners["on-last-page"];
  }
}
